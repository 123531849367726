export class AppConfig {
    // ######### LOCAL ##########
    //url:string ='http://tjagro.test/api/';
    //urlRoot:string ='http://tjagro.test/';
    // ######### PROD  ##########
    //url: string = 'https://api.tjagro.gob.mx/api/';
    //url: string = 'https://trijaem.nuevas-soluciones.net/api/';
    //url: string = 'https://apidev.tjagro.gob.mx/api/';

    url:string = 'https://apidev.tjagro.gob.mx/api/';
    urlRoot:string ='https://apidev.tjagro.gob.mx/';
}