<!-- BOARDS -->
<div id="boards" class="texture-background" fxLayout="column" fxLayoutAlign="start center" fxFlex fusePerfectScrollbar>

  
    <div class="header" fxLayout="row" fxLayoutAlign="center center" fxFlex="0 0 auto" [@animate]="{value:'*',params:{delay:'100ms'}}">
        <div class="logo-tri" [@animate]="{value:'*',params:{scale:'0.5'}}">
            <img width="250" src="assets/images/logos/logogro.png">
        </div>
    </div>
    <div class="header" fxLayout="row" fxLayoutAlign="center center" fxFlex="0 0 auto" [@animate]="{value:'*',params:{delay:'100ms'}}">

    <div class="logo" [@animate]="{value:'*',params:{scale:'0.2'}}">
        <h1 class="name_title" style="
        border: 1px solid white;
        border-radius: 5px 30px 30px 5px;
        padding: 1px 10px 1px 10px;
        background: #ffffffc9;
        margin-left: -10px; font-size: 17px;"><span >e</span>.Justicia Administrativa Guerrero</h1>
    </div>
</div>

    <!-- BOARD LIST -->
    <section *ngFor="let option of boards" fxFlex="0 0 auto" fxLayout="column wrap" fxLayoutAlign="center center">
        <div style="background-color: rgb(255, 255, 255,0.9);; border-radius: 20px;" class="logo" fxLayout="row wrap" [@animate]="{value:'*',params:{scale:'0.2'}}">
            <h1>Portal de Sentencias</h1>
    
        </div>
        <div class="board-list" fxFlex="0 0 auto" fxLayout="row wrap" fxLayoutAlign="center center" [@animateStagger]="{value:'50'}">
            <!-- BOARD -->
            <div class="board-list-item {{board.id}}" *ngFor="let board of option.boards" [routerLink]="[board.path]" routerLinkActive="router-link-active" fxLayout="column" fxLayoutAlign="center center" [@animate]="{value:'*',params:{y:'100%'}}">

                <mat-icon class="s-56" *ngIf="board.icon==='boletin'"  svgIcon="boletin_white">boletin_white</mat-icon>

                <mat-icon class="s-56" *ngIf="board.icon!=='boletin'">{{board.icon}}</mat-icon>


                <div class="board-name">{{board.name}}</div>
            </div>
            <!-- / BOARD -->

            <!-- NEW BOARD BUTTON >
      <div class="board-list-item add-new-board" fxLayout="column" fxLayoutAlign="center center"
           (click)="newBoard()"
           [@animate]="{value:'*',params:{y:'100%'}}">
          <mat-icon class="s-56">add_circle</mat-icon>
          <div class="board-name">Add new board</div>
      </div>
      <!-- / NEW BOARD BUTTON -->

        </div>
        <!-- / BOARD LIST -->
    </section>
</div>
<!-- / BOARDS -->